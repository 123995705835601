<template>
  <div id="container">
  <div class="header" v-if="ces">
        <van-nav-bar
        title=""
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        />
    </div>
    <div class="logo" v-if="ces">
        <!-- <img src="../../assets/images/title.png" alt=""> -->
            <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
    </div>
    <div class="content" v-if="ces">
    <!-- 轮播图 -->
    <div v-if="images!='' && images.length>0" style="height:138px;padding: 7px 5px;background: #fff;
    border-radius: 5px;display: flex;">
    <ul style="width: 100%;border-radius: 5px !important;display: flex;">
      <li class="li_img" style="margin-right: 3px;border-radius: 5px !important;width: 25%;" v-for="(image, index) in images" :key="index">
        <van-image
        @click="sceneImg(image,images.length)"
          style="border-radius: 5px !important;"
          width="100%"
          height="100%"
          :src="image"
        />
      </li>
    </ul>

     

    <!-- <div class="swiper" v-if="images!=''">
        <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
            <img v-lazy="image" />
        </van-swipe-item>
        </van-swipe>
    </div> -->
    </div>
    <!--  -->
    <van-dialog style="width: 260px;" @confirm ="saveImg"  v-model="visable" title="分享茶贴" show-cancel-button="true" cancel-button-text="关闭"  confirm-button-text="保存" confirm-button-color="#ec4899">
        <img  id="compositeCanvas" style="width: 144px;height: 240px;margin-top: 10px" :src="shareImgUrl" />
        <!-- <canvas id="compositeCanvas" width="144px" height="240px"></canvas> -->
      </van-dialog>

    <!-- 帖子描述 -->
    <div class="info_body">
        <div class="title">
            {{info.title}}
        </div>
        <div style="color: #a3a3a3; line-height: 24px;text-align:left">
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="clock-o"
                /><span style="font-size: 12px; margin-right: 5px">{{
                  filterTime(info.publishedAt)
                }}</span>
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="eye-o"
                /><span style="font-size: 12px">{{ info.viewCount }}</span>
        </div>
        <div class="miaoshu">
            <span style="font-weight: 700;color: #000;">地区:</span>&nbsp;&nbsp;{{info.address}}
        </div>
        <div  class="miaoshu">
          <span style="font-weight: 700;color: #000;">年龄:</span>&nbsp;&nbsp;{{info.girlAge}}
        </div>
        <div  class="miaoshu">
          <span style="font-weight: 700;color: #000;">颜值:</span>&nbsp;&nbsp;{{info.girlBeauty}}
        </div>
        <div  class="miaoshu">
          <span style="font-weight: 700;color: #000;">价格:</span>&nbsp;&nbsp;{{info.consumeLv}}
        </div>
        <div class="miaoshu">
          <span style="font-weight: 700;color: #000;">服务:</span>&nbsp;&nbsp;{{info.serveList}}
        </div>
    </div>
    <!-- vip提示 -->
    <div class="vipTip" v-if="userInfo.usertype > 2">
      本站为信息分享平台，并不对寻欢经历负责，请注意个人防范。凡是有要求路费/.上门/红包/定金/保证金/照片验证/视频验证/提前付费
      等类似行为的都是骗子，
      同时也请注意任何形式的推荐办卡行为、引导下载APP行为，请勿上当受骗。碰到有问题的信息，请及时举报给我们删除信息。
      如果发布的信息涉及个人隐私，也请及时举报，我们会核实后第一时间帮你删除处理。
    </div>
    <!-- 帖子信息 -->
    <div class="info" v-if="userInfo.usertype > 2">
        <div class="miaoshu" style="color:#ec4899">
            您是VIP会员,尊享无限制查看全站联系方式！
        </div>
        <div class="miaoshu" v-if=" info.qq && info.qq!=''">
          <span style="font-weight: 700;color: #000;">QQ:</span>&nbsp;&nbsp;<span id="qq" @click="copyText('qq')">{{info.qq}}</span>
        </div>
        <div class="miaoshu" v-if=" info.wechat && info.wechat!=''">
          <span style="font-weight: 700;color: #000;">微信:</span>&nbsp;&nbsp;<span id="vx" @click="copyText('vx')">{{info.wechat}}</span>
        </div>
        <div class="miaoshu" v-if=" info.phone && info.phone!=''">
          <span style="font-weight: 700;color: #000;">手机:</span>&nbsp;&nbsp;<span id="phone" @click="copyText('phone')">{{info.phone}}</span>
        </div>
        <div class="miaoshu" v-if=" info.yuni && info.yuni!=''">
          <span style="font-weight: 700;color: #000;">与你:</span>&nbsp;&nbsp;<span id="yuni" @click="copyText('yuni')">{{info.yuni}}</span>
        </div>
        <div class="miaoshu" v-if=" info.telegram && info.telegram!=''">
          <span style="font-weight: 700;color: #000;">飞机:</span>&nbsp;&nbsp;<span id="telegram" @click="copyText('telegram')">{{info.telegram}}</span>
        </div>
        <div class="miaoshu" v-if=" info.address && info.address!=''">
          <span style="font-weight: 700;color: #000;">地址:</span>&nbsp;&nbsp;<span id="adress" @click="copyText('adress')">{{info.address}}</span>
        </div>
    </div>
    <!-- 积分购买购买 -->
    <van-action-sheet class="jfPay" v-model="jfpayShow" title="茶贴解锁">
      <div class="content">
        <div style="font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: baseline;"><van-icon name="points" />  <span style="font-weight: 700;margin-left: 15px;margin-right: 5px;">20</span><span style="font-size: 20px;font-weight: 700;">积分</span></div>
        <!-- <div>茶贴信息:{{info.title}}</div>
        <van-divider />
        <div class="price">扣除20积分</div>
        <div class="subPay">
          <van-button type="primary" @click="pay" color="#ec4899" style="height:40px;width:80px">确认解锁</van-button>
        </div> -->
        <div style="height: 36px;line-height: 36px;font-size: 14px;display: flex;justify-content: space-between;"><span style="color:#a3a3a3">会员权益</span><span style="color:#ec4899">升级会员可免费解锁</span></div>
        <van-divider />
        <div style="height: 36px;line-height: 36px;font-size: 14px;display: flex;justify-content: space-between;"><span style="color:#a3a3a3">茶贴信息</span><span style="color:#000">{{info.title}}</span></div>
        <van-divider />
        <div class="subPay" style="display: flex;
    justify-content: space-around;">
          <van-button type="primary" @click="sj" color="#ec4899" style="height:40px;width:80px">升级会员</van-button>
          <van-button type="primary" @click="pay" color="#ec4899" style="height:40px;width:80px">确认解锁</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- 未登录提示 -->
    <div class="login_tip" v-if="loginStatus != true">
        <div class="tips">
            您需要登录才可以查看联系方式和详细地址！
        </div>
        <div class="login_btn">
            <van-button type="primary" color="#ec4899" style="height:40px;width:80px" @click="goLogin">立即登录</van-button>
        </div>
        <div class="aaa">
            <span>提示:</span>
            <div class="bbb">
                <li>1、升级Vip会员即可享受App风流贴无限制查看</li>
                <li>2、通过发布风流贴赚取积分,查看帖子即扣除20积分</li>
            </div>
        </div>
    </div>
    <!-- 非会员提示 -->
    <div class="notVip" v-if="userInfo.usertype == 1">
        <div class="text">
            本信息将扣除20积分,您的账户积分不足或者VIP会员已到期,您可以通过升级VIP无限制查看联系方式,或者发布信息赚取积分!
        </div>
        <div class="login_btn">
            <van-button type="primary" color="#ec4899" style="height:40px;width:80px" @click="jfPay">积分解锁</van-button>
            <van-button type="primary" color="#ec4899" style="height:40px;width:80px" @click="sj">升级VIP</van-button>
            <!-- <van-button type="primary" color="#ec4899" style="height:40px;width:80px" @click="shareImg">分享好友</van-button> -->
        </div>
    </div>
    <!-- 帖子描述 -->
    <div class="desc">
      <span style="font-weight: 700;color: #000;">体验描述:</span>&nbsp;&nbsp;{{info.desc}}
    </div>
    </div>
        <!-- 会员升级 -->
      <van-popup v-model="sjhyShow"   closeable
  position="bottom"
  :style="{ height: '100%',overflow: 'hidden' }">
    <div style="width:100%;height:50px;line-height:50px;font-size:16px;text-align:left;font-weight:1000;padding-left:20px;background-color:#f5f5f5;color:#ec4899">升级VIP</div>
    <div class="content_tip">
    <div class="content">
      <div class="font">
        <span style="font-weight:700;color:#000">第一步,查看会员类型和价格</span>
        <table align="center" border-collapse="collapse">
            <tr>
              <th align="center" valign="middle">类型</th>
              <th align="center" valign="middle">价格</th>
              <th align="center" valign="middle">查看所有内容</th>
              <th align="center" valign="middle">有效期</th>
            </tr>
            <tr>
              <td align="center" valign="middle">永久会员</td>
              <td align="center" valign="middle">88.00</td>
              <td align="center" valign="middle">51风流/一楼一凤</td>
              <!-- <td align="center" valign="middle">90天</td> -->
              <td align="center" valign="middle">永久</td>

            </tr>
            <tr>
              <td align="center" valign="middle">超级会员</td>
              <td align="center" valign="middle">158.00</td>
              <td align="center" valign="middle">51风流/一楼一凤/一品楼</td>
              <td align="center" valign="middle">永久</td>
            </tr>
            <tr>
              <td align="center" valign="middle">至尊会员</td>
              <td align="center" valign="middle">288.00</td>
              <td align="center" valign="middle">全部</td>
              <td align="center" valign="middle">永久</td>
            </tr>
        </table>
        <span style="line-height:20px;font-size:14px;color:#ec4899">小提示：升级VIP会员后有效期内无需积分浏览全站内容。</span><br>
        <span style="font-weight:700;color:#000;margin-top:10px">第二步,前往卡密平台购买对应的会员升级码</span><br>
        <span style="line-height:20px;font-size:14px;color:#ec4899">温馨提示：如果您需要使用zfb或者vx支付,请联系下方客服充值。
          客服QQ：1538373956
        </span>
        <div class="font">
        付款成功后记得返回本页面,输入升级码完成升级。
        </div>
        <!-- <div class="zfxx">微信支付</div> -->
        <!-- <div class="vipBtn">
          <li @click="jdhy_vx">季度会员</li>
          <li @click="ndhy_vx">年度会员</li>
          <li @click="yjhy_vx">永久会员</li>
        </div>
        <div class="zfxx">QQ支付</div> -->
        <div class="vipBtn">
          <li @click="jdhy">永久会员</li>
          <li @click="ndhy">超级会员</li>
          <li @click="yjhy">至尊会员</li>
          <li @click="orderQuery">卡密查询</li>
        </div>
          <span style="line-height:20px;font-size:14px;color:#ec4899">客服邮箱：51flwhome@gmail.com,如果您在购买和使用中出现任何问题,
            请发邮件与我们联系,我们会尽快给您答复并解决您的问题。</span><br>
        <span style="font-weight:700;color:#000;margin-top:10px">第三步,在下方输入您购买的升级码完成会员升级</span>
        <div class="font">
        输入升级码,将自动为您更新会员等级。
        <div class="shengji">
          <div class="inputvip">
            <input type="text" name="" id="" placeholder="输入升级码">
          </div>
          <div class="sjbtn">
            <li @click="hysj">立即升级</li>
          </div>
        </div>
          <span style="line-height:20px;font-size:14px;color:#ec4899">*请勿为了发布信息去升级VIP，我们审核信息不会因为VIP会员而区别对待，
            只和信息本身的质量有关系。</span><br>
            <span style="line-height:20px;font-size:14px;color:#ec4899">*请勿为了信息采集和共享账号去升级VIP，如果你的账号:
              使用频率异常将会被系统自动封号。</span><br>
        <div style="font-size:18px;font-weight:700">升级帮助</div>
        <span style="font-weight:700;color:#000;margin-top:10px">充值成功了，为什么还不能看信息?</span>
        <div class="font">
        第一次充值成为VIP会员后，请重新刷新页面或者退出用户名重新登录一下即可。
        </div>
        <span style="font-weight:700;color:#000;margin-top:10px">充值中碰到问题，升级失败了怎么办?</span>
        <div class="font">
        请提供你的相关账号以及支付信息，然后发邮件给:51flwhome@gmail.com，我们会尽快给你处理。
        </div>
        <span style="font-weight:700;color:#000;margin-top:10px">有事怎么联系客服?</span>
        <div class="font">
        请提供你的账号以及详细描述你的问题，然后发邮件给:待定@gmail.com，我们会尽快给你处理。
        </div>
        </div>
     </div>
    </div>
    </div>
      </van-popup>
      <Loder ref="dialog"></Loder>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { getDataById } from "../../model/index"
import { getJFXX,payTZ,insetIds,getTZ,getShareImg } from "../../model/user"
import moment from "moment";
import { Toast } from 'vant';
import { mapState,mapMutations } from "vuex"
import Loder  from './compoments/index.vue'
import { v4 as uuidv4 } from 'uuid';
import { ImagePreview } from 'vant';
export default {
    components: {
      Loder
    },
    data() {
        return {
            id:"",
            images: [],
            // baseUrl: "https://r1.img7.xyz/info/picture/",
          // baseUrl: "http://38.54.111.82/info/picture/",
          baseUrl: "http://38.54.111.182/info/picture/",
            status:2,   // 0 未登录  1普通用户 2会员账号
            info:"",
            sjhyShow:false,
            jfpayShow:false,
            titleTZ:"",
            ces:false,
            buyIds:[],
          ids: "",
          visable: false,
          shareImgUrl:""
            
        }
    },
   watch:{
      'info'(val){
        if(val){
        console.log(val,1566666);
        setTimeout(()=>{
          this.ces = true
        },500)
        this.$nextTick(()=>{
          this.$refs['dialog'].changeVisable()
        })
        }

        
      }
    },
    created() {
        // console.log(this.$route.query.id);
        this.id = this.$route.query.id
      this.getData()
        
    },
    computed:{
        ...mapMutations(["changeFlag","changeFlag1"]),
        ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },
    },
 


  methods: {
    ...mapMutations(['loginOut']),
    saveImg(action, done) { 
         // 下载按钮点击事件
      // document.getElementById('downloadBtn').addEventListener('click', function () {
          // 获取canvas元素
          // const canvas = document.getElementById('compositeCanvas');
          // 将canvas内容转换为数据URL (PNG格式)
          const image = document.getElementById('compositeCanvas')

          const link = document.createElement('a');
          link.href = image.src;
          link.download = `${this.info.title}.png`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link)

          // 自动触发点击事件，开始下载
          link.click();
      // });
      this.visable = false
      done();
    },
    shareImg() { 
      let data = {
        id: this.id,
        type:1
      }
      getShareImg(data).then((res) => { 
        console.log('res11111', res)
        if (res.data.code == 200) {
          this.shareImgUrl = res.data.data.imagePath
          this.visable = true
        } else { 
          Toast.fail('生成失败')
          this.visable = false
        } 
      })
    },  
    setIMG(){
      document.addEventListener('DOMContentLoaded', function() {
    const compositeCanvas = document.getElementById('compositeCanvas');
    const ctx = compositeCanvas.getContext('2d');
  
    // 创建一个圆角矩形作为底图
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(compositeCanvas.width, 0);
    ctx.lineTo(compositeCanvas.width, compositeCanvas.height);
    ctx.lineTo(0, compositeCanvas.height);
    ctx.closePath();
    ctx.fillStyle = '#000'; // 设置底图颜色
    ctx.fill();
  
    // 添加自定义图片
    const image = new Image();
    image.onload = function() {
      compositeCanvas.width = image.width; // 设置画布宽度为图片宽度
      compositeCanvas.height = image.height; // 设置画布高度为图片高度
      ctx.drawImage(image, 0, 0, compositeCanvas.width, compositeCanvas.height);
  
      // 添加文字
      ctx.fillStyle = '#ec4899'; // 设置文字颜色
      ctx.font = '14px Arial'; // 设置字体和大小
      const text1 = '设置字体和大小文字颜色';
      // const textWidth1 = ctx.measureText(text1).width;
        //   const textX = (compositeCanvas.width - textWidth) / 2;
      const textX1 = compositeCanvas.width  -140;
      const textY1 = compositeCanvas.height - 69; // 假设文字在图片底部，距离底部24像素
      ctx.fillText(text1, textX1, textY1);
        

        // 添加文字
      ctx.fillStyle = '#000'; // 设置文字颜色
      ctx.font = '10px Arial'; // 设置字体和大小
      const text2 = '更多尽在51风流';
      // const textWidth2 = ctx.measureText(text2).width;
        //   const textX = (compositeCanvas.width - textWidth) / 2;
      const textX2 = compositeCanvas.width  -140;
      const textY2 = compositeCanvas.height - 20; // 假设文字在图片底部，距离底部24像素
      ctx.fillText(text2, textX2, textY2);

         // 添加文字
      ctx.fillStyle = '#000'; // 设置文字颜色
      ctx.font = '8px Arial'; // 设置字体和大小
      const text3 = '浏览器扫一扫看详情';
      // const textWidth3 = ctx.measureText(text3).width;
        //   const textX = (compositeCanvas.width - textWidth) / 2;
      const textX3 = compositeCanvas.width  -140;
      const textY3 = compositeCanvas.height - 5; // 假设文字在图片底部，距离底部24像素
      ctx.fillText(text3, textX3, textY3);
        

    };
    image.src = './final_image.png'; // 设置图片路径
    image.width = 144
    image.height = 240
  });
    },
        // 图片预览
    sceneImg(images, index) {
      if (!this.loginStatus) { 
        Toast.fail('请登陆后查看！')
      } else {
        if (this.userInfo.usertype > 2) {
          ImagePreview({
            images: [images], //需要预览的图片 URL 数组
            showIndex:false, //是否显示页码
            loop:false, //是否开启循环播放
            startPosition:index //图片预览起始位置索引
          })
        } else { 
          Dialog.alert({
            title: '温馨提示',
            message: '仅积分购买此联系方式的用户和VIP会员可使用图片放大功能,请使用积分购买或升级VIP会员。',
          }).then(() => {
            // on close
          });
        }
      
      }
       
        },
        sj(){
          this.sjhyShow = true
          let time = this.$getNYRSFM()
          let uuid = uuidv4()
          if(this.$store.state.user.loginStatus){
            this.$operLog('点击会员升级',uuid,this.$store.state.user.userInfo.username,this.$store.state.user.userInfo.usertype,this.$store.state.user.userInfo.createTime,window.location.host,time)
          }else{
            this.$operLog('点击会员升级',uuid,'','','',window.location.host,time)
          }


        },
          // 会员升级
        hysj(){
          // alert("请到个人中心进行升级！")
          // Toast.fail('请到主页进行升级')
          Dialog.alert({
            title: '温馨提示',
            message: '购买卡密后,主页VIP升级输入卡密即可',
          }).then(() => {
            // on close
          });
        },
        // 季度会员
        jdhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.sdfkw.xyz/details/1157AE42"
        },
        // 年度会员
        ndhy(){
          // window.location.href="http://dolve.xyz"
           window.location.href="https://www.sdfkw.xyz/details/C9C3C98E"

        },
        // 永久会员
        yjhy(){
          // window.location.href="http://dolve.xyz"
          window.location.href="https://www.sdfkw.xyz/details/7C4CC4C5"

        },
        orderQuery(){
          window.location.href="https://www.sdfkw.xyz/orderquery"
        },
         // 季度会员
        jdhy_vx(){
          window.location.href=""
        },
        // 年度会员
        ndhy_vx(){
          window.location.href="https://vip.zaanz.cn/details/A2DBFE26"
        },
        // 永久会员
        yjhy_vx(){
          window.location.href="https://vip.zaanz.cn/details/75D10F82"
        },
        // 升级会员弹窗
        sjhy(){
          this.sjhyShow = true
        },
        getData(){
            let data= {
                id:this.id
            }
          getDataById(data).then((res) => {
            console.log('res999', res)
            if (res.data.code == 401) {
              // alert(1)
              Dialog.alert({
                    title: '温馨提示',
                    message: '认证失败,重新登陆',
                  }).then(() => {
                    // on close
                    this.loginOut()
                    this.$router.push({
                      path:'/home'
                    })
                  });
            } else { 
              // console.log(res.data.data,55);
                this.info = res.data.data[0]
                let fbTimer = res.data.data[0].publishedAt
                console.log(this.info,66);
                let arrPic = res.data.data[0].picture.split(",")
                if(arrPic&&arrPic.length>0 && arrPic[0]!=''){
                 let arrList = arrPic.map((item)=>{
                    // if(item.indexOf('/') != -1){
                    //   return 'http://38.60.204.69/index.php?url='+this.baseUrl  + item+'&refer=https://dx41.xyz/'
                    // }
                    // else{
                    //   return 'http://38.60.204.69/index.php?url='+this.baseUrl +this.filterTimePIC(fbTimer)+'/' + item+'&refer=https://dx41.xyz/'
                    // }
                    if(item.indexOf('/') != -1){
                      return this.baseUrl  + item
                    }
                    else{
                      return this.baseUrl +this.filterTimePIC(fbTimer)+'/' + item
                    }
                 })
                    // let picList = 
                    // this.images = this.images.push(pic)
                    console.log(arrList,55555,arrPic);
                    this.images = arrList
                }
            }

            })
        },
        // 积分解锁
        jfPay(){
          this.jfpayShow = true
        },
        pay(){
          // console.log(this.userInfo,123);

          let data = {
            username:this.userInfo.username
          }
          getTZ(data).then((res)=>{
            if(res.status == 200){
              console.log(res.data.data[0].buyed,12333333);
             
              
              // this.buyIds.push(res.data.data[0].buyed)
              if(res.data.data[0].buyed == "" || res.data.data[0].buyed == null){
                  getJFXX(data).then((res)=>{
                  if(res.status == 200){
                    // console.log(res,156);
                    let jfNum = res.data.data[0].score
                    console.log(jfNum);
                    if(jfNum - 20 < 0){
                      // Toast.fail('积分不足.请先充值！')
                      Dialog.alert({
                                title: '温馨提示',
                                message: '积分余额不足,请充值积分',
                              }).then(() => {
                                // on close
                              });
                    }else{
                      let data = {
                        username:this.userInfo.username,
                        score:jfNum - 20
                      }
                      payTZ(data).then((res)=>{
                        if(res.status == 200){
                          // console.log(res,123);
                          this.jfpayShow = false
                          let data = {
                            burId:this.id+','+this.ids,
                            username:this.userInfo.username
                          }
                          insetIds(data).then((res)=>{
                            if(res.status == 200){
                              // Toast.success('已解锁,请回主页查看');
                              Dialog.alert({
                                title: '温馨提示',
                                message: '茶贴已解锁,请返回主页我的解锁进行查看',
                              }).then(() => {
                                // on close
                              });
                              this.$store.commit('updataJF',jfNum - 20)
                              let time = this.$getNYRSFM()
                              let uuid = uuidv4()
                              if(this.$store.state.user.loginStatus){
                                this.$operLog('解锁帖子',uuid,this.$store.state.user.userInfo.username,this.$store.state.user.userInfo.usertype,this.$store.state.user.userInfo.createTime,window.location.host,time)
                              }else{
                                this.$operLog('解锁帖子',uuid,'','','',window.location.host,time)
                              }
                            }
                          })
                        }
                      })
                    }
                  }
                })
              }else{
                 this.ids = res.data.data[0].buyed
                this.buyIds = res.data.data[0].buyed.split(',')
                console.log(this.buyIds,133);
          
                
                if(this.buyIds.indexOf(this.id) != -1){
                  Dialog.alert({
                                title: '温馨提示',
                                message: '茶贴已解锁,请返回主页我的解锁进行查看',
                              }).then(() => {
                                // on close
                              });
                 this.jfpayShow = false
                }else{
                  getJFXX(data).then((res)=>{
                  if(res.status == 200){
                    // console.log(res,156);
                    let jfNum = res.data.data[0].score
                    console.log(jfNum);
                    if(jfNum - 20 < 0){
                      Dialog.alert({
                                title: '温馨提示',
                                message: '积分余额不足,请充值积分',
                              }).then(() => {
                                // on close
                              });
                    }else{
                      let data = {
                        username:this.userInfo.username,
                        score:jfNum - 20
                      }
                      payTZ(data).then((res)=>{
                        if(res.status == 200){
                          // console.log(res,123);
                          this.jfpayShow = false
                          let data = {
                            burId:this.id+','+this.ids,
                            username:this.userInfo.username
                          }
                          insetIds(data).then((res)=>{
                            if(res.status == 200){
                              // Toast.success('已解锁,请回主页查看');
                              Dialog.alert({
                                title: '温馨提示',
                                message: '茶贴已解锁,请返回主页我的解锁进行查看',
                              }).then(() => {
                                // on close
                              });
                              this.$store.commit('updataJF',jfNum - 20)
                            }
                          })
                        }
                      })
                    }
                  }
                })
                }



              }
               
             
            }
          })
          

        },
        onClickLeft(){
          
            // this.$router.back()
            window.history.back(-1)
         
        },
        onClickRight(){
        },
 
        // 格式化时间
        filterTime(value) {
        if (value != null || value != "") {
            let val = value;
            let momentObj = moment(val * 1).format("YYYY-MM-DD");
            return momentObj;
        } else {
            return "";
        }
        },
        // 去登陆
        goLogin(){
            this.$router.push({
                path:'./login'
            })
        },
      filterTimePIC(value) {
        if (value != null || value != "") {
          let val = value;
          let momentObj = moment(val * 1).format("YYMMDD");
          console.log(momentObj,"156666");
          return momentObj;
        } else {
          return "";
        }
        },
     copyText(id) { //传id名
        const copyDOM = document.getElementById(id) // 获取将要复制的dom
        if (!copyDOM) {
          Toast.success('没有内容')
          return;
        }
        // 创建text area
        const textArea = document.createElement('textarea')
        textArea.value = copyDOM.innerText
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = 'absolute'
        textArea.style.opacity = '0'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select() // 选中文本
        const successful = document.execCommand('copy') // 执行 copy 操作
        if (successful) {
          Toast.success('复制成功！')
        } else {
        Toast.success('复制失败，请手动复制！')
        }
        textArea.remove()
      }


    },
async mounted() {
    // this.ces = true
      // this.ces = true
    this.changeFlag();
  },
  destroyed() {
    this.changeFlag1();
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
}

 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
.content .info_body{
    box-sizing: border-box;
    width: 100%;
    height: 225px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .info_body .title{
    height: 40px;
    width: 100%;
    line-height: 40px;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
}
.content .info_body .miaoshu{
    text-align: left;
    font-size: 14px;
    color: #525252;
    line-height: 30px;
    /* overflow: hidden; */
    height: 30px;
    white-space:nowrap;
   overflow:hidden;
   text-overflow:ellipsis;
}
.content .login_tip{
    box-sizing: border-box;
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .login_tip .tips{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
}
.content .login_tip .login_btn{
    height: 50px;
}
.content .login_tip .aaa{
    height: 55px;
    margin-top: 4px;
    cursor: pointer;
}
.content .login_tip .aaa span{
    display: block;
    font-size: 14px;
    float: left;
    color: #ec4899;
    margin-top: -2px;
}
.content .login_tip .aaa .bbb{
    float: left;
    width: 300px;
}
.content .login_tip .aaa .bbb li{
    height: 25px;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
    color: #ec4899;
}
.content .desc{
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
}
.content .info{
    box-sizing: border-box;
    width: 100%;
    /* height: 170px; */
    height: auto;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .info .miaoshu{
    text-align: left;
    font-size: 14px;
    color: #525252;
    line-height: 30px;
    overflow: hidden;
}
.content .vipTip{
  box-sizing: border-box;
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
    font-size: 14px;
    text-align: left;
    color: goldenrod;
}
.content .notVip{
    box-sizing: border-box;
    width: 100%;
    height: 140px;
    padding: 5px 10px;
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;
}
.content .notVip .text{
    width: 100%;
    font-size: 14px;
    text-align: left;
}
 .content .notVip .login_btn{
    height: 50px;
    text-align: left;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}


.content_tip{
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 5px 10px  0;
  box-sizing: border-box;
}
.content_tip .content{
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  padding: 10px 5px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.content_tip .content .font{
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}
.zfxx{
  font-size: 16px;
  padding-left: 20px;
  color: #ec4899;
  font-weight: 700;
}
.vipBtn{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  /* line-height: 60px; */
  margin-top: 5px;
}
.vipBtn li{
  background-color: #ec4899;
  width: 22%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
.vipBtn li:nth-last-child(1){
  background-color: #1a73e8;
  border-radius: 20px;
}
.shengji{
  width: 100%;
  height: 50px;
}
.shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
.shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
table,table tr th,table tr td{
  border: 1px solid #ccc;
}
table{
  border-collapse: collapse;
  width: 100%;
}
.content {
  /* padding: 16px 16px 160px; */
}
.jfPay{
  box-sizing: border-box;
  padding: 0 20px;
  height: 50%;
  font-size: 18px;
  color: #ec4899;
}
.jfPay .content{
  text-align: left;
  height: calc(100% - 50px);
  height: 100%;
}
.jfPay .content .subPay{
  text-align: center;
  margin-top: 40px;
}
.jfPay .content .price{
  text-align: center;
  margin-top: 30px;
  font-size: 24px;
}
.jfPay /deep/ .van-action-sheet__header{
  margin-top: 20px;
  margin-bottom: 15px;
  color: #000;
}
.content /deep/ .van-action-sheet__close{
  right: 15px;
  /* color: #ec4899; */
} 
.li_img /deep/.van-image img{
  border-radius: 5px !important;
}
</style>